/**
 * Transforms the Facets.fields component configuration into a transformFacets function
 * which can be understood by the Answers Search UI.
 * 
 * @param {DisplayableFacet[]} facets from answers-core
 * @param {FilterOptionsConfig} config the config of the FilterOptionsConfig from answers-search-ui
 * @returns {(DisplayableFacet | FilterOptionsConfig)[]}
 */
export default function customTransformFacets (facets, config) {
  if(!config || !('fields' in config)) {
    return facets;
  }

  return facets.map(facet => {
    // The "Affiliation", "Specialty", and "Subspecialty" facet should have its options sorted alphabetically
    if (typeof facet.fieldId === 'string' &&
        (facet.fieldId === 'c_affiliation' || facet.fieldId === 'c_answersSpecialty.name' || facet.fieldId === 'c_answersSubspecialties.name') &&
        Array.isArray(facet.options)) {
      facet.options.sort((lhs, rhs) => {
        if (lhs.displayName < rhs.displayName) { return -1; }
        if (lhs.displayName > rhs.displayName) { return 1; }
        return 0;
      });
    }

    // Any "Yes/No" fields should always show the "Yes" option first, and then "No"
    //  ("Yes/No" fields have `displayName` values "true/false" in the platform)
    if (Array.isArray(facet.options) &&
        facet.options.length === 2 &&
        facet.options.every(option => ['true', 'false'].includes(option.displayName))) {
      // (A reverse alphabetical sort puts them in the correct order)
      facet.options.sort((lhs, rhs) => {
        if (lhs.displayName < rhs.displayName) { return 1; }
        if (lhs.displayName > rhs.displayName) { return -1; }
        return 0;
      });
    }

    const isConfigurationForFacet = facet.fieldId in config.fields;
    if (!isConfigurationForFacet) {
      return facet;
    }
    const facetConfig = config.fields[facet.fieldId];

    let options = facet.options;

    if ('fieldLabels' in facetConfig) {
      options = facet.options.map(option => {
        const fieldLabels = facetConfig.fieldLabels;

        const displayName = (option.displayName in fieldLabels)
          ? fieldLabels[option.displayName]
          : option.displayName;

        return Object.assign({}, option, { displayName });
      })
    }

    const filterOptionsConfig = Object.entries(facetConfig).reduce((filterOptions, [option, value]) => {
      if (option !== 'fieldLabels') {
        filterOptions[option] = value;
      }
      return filterOptions;
    }, {});
    
    return Object.assign({}, facet, filterOptionsConfig, { options });
  });
}