export function listNames(entityList) {
    if(!entityList) {
      return null;
    }
    let names = [];
    entityList.forEach(element => names.push(" " + element.name));
    return names;
  }

export function toMiles(profile, key = 'd_distance', displayUnits = 'mi') {

  var computedDistance = 'd_distance';
  if (profile['d_distanceFromFilter']) {
    computedDistance =  'd_distanceFromFilter';
  }
  const distanceInMiles = profile[computedDistance] / 1609.344; // Convert meters to miles
  return distanceInMiles.toFixed(1) + ' ' + displayUnits;
}
/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
